<template>
  <v-container fluid tag="section">
    <!-- TÍTULO  -->
    <v-row justify="center">
      <v-col cols="12" sm="10">
        <page-top-title>
          <template v-slot:toptitle-icon>mdi-pill</template>
          <template v-slot:toptitle-text>Drug Classification</template>
        </page-top-title>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" md="7">
        <v-card class="pa-0">
          <v-card-title class="solid-heading"
            ><h3 class="white--text font-weight-regular">
              Drug categories
            </h3></v-card-title
          >
          <v-card-text class="pa-4">
            <Tree
              v-if="treeData.length > 0"
              :value="treeData"
              foldAllAfterMounted
            >
              <span slot-scope="{ node, index, path, tree }">
                <b v-if="node.isCategory" @click="toggleFold(tree, node, path)">
                  {{ node.$folded ? "+" : "-" }}
                </b>
                <span v-if="!node.isCategory && !node.has_epg">{{
                  node.text
                }}</span>
                <a
                  @click="
                    $router.push({
                      name: 'Explorer.drug_detail',
                      params: { id: node.id }
                    })
                  "
                  v-else-if="!node.isCategory && node.has_epg"
                  >{{ node.text }}</a
                >
                <span
                  style="cursor: pointer;"
                  v-else
                  @click="toggleFold(tree, node, path)"
                  >{{ node.index }} {{ node.text }}</span
                >
              </span>
            </Tree>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageTopTitle from "@/components/mylogy/PageTopTitle.vue";
import "he-tree-vue/dist/he-tree-vue.css";
import { Tree, Fold } from "he-tree-vue";

export default {
  name: "ApdxDrugClass",
  components: {
    PageTopTitle,
    Tree: Tree.mixPlugins([Fold])
  },
  data() {
    return {
      treeData: []
    };
  },
  computed: {},
  mounted() {
    this.getRootCategories();
  },
  methods: {
    getRootCategories() {
      let self = this;
      fetch(
        `https://strapi.mylogy.xyz/root-epg-drug-categories?token=zyroivKXeWFPxWf9bMXZBbaKCc9fwCprxuAcqKeEt3xZb8g9`
      ).then(function(response) {
        response.json().then(function(data) {
          self.treeData = data.rootCategories.map(c => {
            return {
              text: c.en,
              index: c.index,
              id: c._id,
              children: c.kb_drugs
                .map(d => {
                  return {
                    text: d.main_names.en,
                    index: "",
                    id: d._id,
                    has_epg: d.has_epg,
                    children: [],
                    isCategory: false
                  };
                })
                .sort((a, b) =>
                  a.text > b.text ? 1 : b.text > a.text ? -1 : 0
                ),
              isCategory: true
            };
          });
        });
      });
    },
    toggleFold(tree, node, path) {
      let self = this;
      if (node.children.length == 0) {
        fetch(
          `https://strapi.mylogy.xyz/epg-drug-categories/${node.id}/children?token=zyroivKXeWFPxWf9bMXZBbaKCc9fwCprxuAcqKeEt3xZb8g9`
        ).then(function(response) {
          response.json().then(function(data) {
            console.log(node);
            node.children = data.childrenCategories.map(c => {
              return {
                text: c.en,
                index: c.index,
                id: c._id,
                $folded: true,
                isCategory: true,
                children: c.kb_drugs
                  .map(d => {
                    return {
                      text: d.main_names.en,
                      index: "",
                      id: d._id,
                      has_epg: d.has_epg,
                      children: [],
                      isCategory: false
                    };
                  })
                  .sort((a, b) =>
                    a.text > b.text ? 1 : b.text > a.text ? -1 : 0
                  )
              };
            });
            tree.toggleFold(node, path);
            node.$folded = false;
          });
        });
      } else {
        node.$folded = !node.$folded;
      }
    }
  }
};
</script>

<style>
.he-tree .tree-node {
  border: none !important;
  box-shadow: 0 0 20px -10px;
  border-radius: 20px;
  background: white;
  padding: 20px;
  margin-bottom: 20px;
}
.he-tree .tree-node b {
  cursor: pointer;
}
</style>
