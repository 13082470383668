var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('page-top-title',{scopedSlots:_vm._u([{key:"toptitle-icon",fn:function(){return [_vm._v("mdi-pill")]},proxy:true},{key:"toptitle-text",fn:function(){return [_vm._v("Drug Classification")]},proxy:true}])})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-card',{staticClass:"pa-0"},[_c('v-card-title',{staticClass:"solid-heading"},[_c('h3',{staticClass:"white--text font-weight-regular"},[_vm._v(" Drug categories ")])]),_c('v-card-text',{staticClass:"pa-4"},[(_vm.treeData.length > 0)?_c('Tree',{attrs:{"value":_vm.treeData,"foldAllAfterMounted":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var index = ref.index;
var path = ref.path;
var tree = ref.tree;
return _c('span',{},[(node.isCategory)?_c('b',{on:{"click":function($event){return _vm.toggleFold(tree, node, path)}}},[_vm._v(" "+_vm._s(node.$folded ? "+" : "-")+" ")]):_vm._e(),(!node.isCategory && !node.has_epg)?_c('span',[_vm._v(_vm._s(node.text))]):(!node.isCategory && node.has_epg)?_c('a',{on:{"click":function($event){return _vm.$router.push({
                    name: 'Explorer.drug_detail',
                    params: { id: node.id }
                  })}}},[_vm._v(_vm._s(node.text))]):_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toggleFold(tree, node, path)}}},[_vm._v(_vm._s(node.index)+" "+_vm._s(node.text))])])}}],null,false,413087840)}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }